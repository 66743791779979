<template lang="html" src="./pageBrandAdd.template.vue"></template>

<style lang="scss" src="./pageBrandAdd.scss"></style>

<script>
import { FormAddress, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandAdd.i18n');

export default {
  name: 'PageBrandAdd',
  components: { FormAddress },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      warehouseOffers: [],
      selectedOffer: null,
      keyComponent: 0,
      loading: false,
      nameRules: [(value) => !!value || this.$t('nameRules')],
      newAddress: new this.$BcmModel.Address(),
      newBrand: new this.$BcmModel.Brand(),
      isValidVAT: false,
    };
  },
  created() {
    this.$apiInstance
      .getAdminWarehouseOffers()
      .then(offers => {
        this.warehouseOffers = offers;
      })
      .catch(error => {
        this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
      });
  },
  methods: {
    brandNameChanged() {
      if (
        this.newBrand.name &&
        this.newAddress &&
        (!this.newAddress.company || this.newAddress.company.trim() === '')
      ) {
        this.newAddress.company = this.newBrand.name;
        this.keyComponent++;
      }
    },
    submitForm() {
      if (
        this.$refs.newSubscriptionForm.validate() &&
        this.$refs.newBrandForm.validate() &&
        this.$refs.newAddressForm.validate()
      ) {
        /**
         * Prepare default configuration
         */
        const offer = JSON.parse(JSON.stringify(this.selectedOffer));
        /**
         * Cleanup invalid attribute for modifyBrandSubscriptionBody
         */
        delete offer.subscription.code;
        delete offer.subscription.nextBillingDate;
        delete offer.subscription.syncInvoiceUUID;
        /**
         * Add required attribute for modifyBrandSubscriptionBody
         */
        offer.subscription.invalid_after = null;
        offer.subscription.discounts = {};
        /**
         * Set payment method to manual for handle Subscription Invoices manually by default 
         * (this won't generate subscription invoice)
         */
        offer.subscription.payment_method = 'MANUAL';

        this.loading = true;
        const addBrandBody = new this.$BcmModel.AddBrandBody(this.newBrand.name);
        this.$apiInstance
          .addBrand(addBrandBody)
          .then(
            (newBrandData) => {
              this.$apiInstance
                .modifyBrandAddress(newBrandData._id, this.newAddress)
                .then(
                  (newAddressData) => {
                    this.newAddress = newAddressData;
                    /**
                     * Component BeelseNotifications used
                     */
                    this.$notification.notify('SUCCESS',this.$t('BrandSuccessfullyAdded'));
                    /**
                     * Update Subscription
                     */
                    this.$apiInstance
                      .modifyBrandSubscription(newBrandData._id, offer.subscription)
                      .then(
                        () => {
                          this.$notification.notify('SUCCESS',this.$t('AddedDefaultSubscriptionToTheBrand'));
                          /**
                           * Update Features
                           */
                          this.$apiInstance
                            .modifyBrandFeatures(newBrandData._id, offer.features)
                            .then(
                              () => {
                                this.$notification.notify('SUCCESS',this.$t('AddedDefaultFeaturesToTheBrand'));
                                /**
                                 * Redirect
                                 */
                                this.$router.push({name: 'Brands'});
                              },
                              (error) => {
                                this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
                              }
                            );
                        },
                        (error) => {
                          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
                        }
                      );
                  },
                  (error) => {
                    this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
                    this.$router.push({name: 'Brand', params: { brandUUID: `${newBrandData._id}`}});
                  }
                );
            },
            (error) => {
              /**
               * ERROR ADD BRAND
               */
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updateAddress(address) {
      this.newAddress = address;
    },
    async validateVAT(countryCode, vat) {
      this.isValidVAT = await this.$apiInstance.validateEuropeanVAT(countryCode, vat)
        .then((data) => {
          return true;
        }, (error) => {
          return false;
        });
    }
  },
};
</script>
