var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-brandAdd"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('AddBrand')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("subscriptionPlan")))]), _c('v-card-text', [_c('v-form', {
    ref: "newSubscriptionForm"
  }, [_c('v-select', {
    attrs: {
      "menu-props": {
        offsetY: true
      },
      "items": _vm.warehouseOffers,
      "item-text": function (item) {
        return item._id;
      },
      "item-value": function (item) {
        return item;
      },
      "label": _vm.$t('subscriptionPlan'),
      "outlined": "",
      "required": "",
      "rules": _vm.nameRules
    },
    model: {
      value: _vm.selectedOffer,
      callback: function ($$v) {
        _vm.selectedOffer = $$v;
      },
      expression: "selectedOffer"
    }
  })], 1)], 1), _c('v-card-title', [_vm._v(_vm._s(_vm.$t("brandName")))]), _c('v-card-text', [_c('v-form', {
    ref: "newBrandForm"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('name'),
      "rules": _vm.nameRules,
      "outlined": "",
      "required": ""
    },
    on: {
      "change": _vm.brandNameChanged
    },
    model: {
      value: _vm.newBrand.name,
      callback: function ($$v) {
        _vm.$set(_vm.newBrand, "name", $$v);
      },
      expression: "newBrand.name"
    }
  })], 1)], 1), _c('v-card-title', [_vm._v(_vm._s(_vm.$t("brandAddress")))]), _c('v-card-text', [_c('formAddress', {
    key: _vm.keyComponent,
    ref: "newAddressForm",
    attrs: {
      "address": _vm.newAddress,
      "restricted-country-list": [],
      "is-valid-v-a-t": _vm.isValidVAT,
      "show-first-name": false,
      "show-last-name": false,
      "required-company": true,
      "required-zip-code": true,
      "required-phone-number": true,
      "required-v-a-tnumber": true,
      "allow-to-set-not-applicable-v-a-t": true
    },
    on: {
      "updated": _vm.updateAddress,
      "validateVAT": _vm.validateVAT
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "to": {
        name: 'Brands'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")], 1), _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "success"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }